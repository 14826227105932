import React, {useEffect} from 'react';
import {FaFacebook, FaLinkedin, FaTwitter, FaInstagram} from 'react-icons/fa'
import Logo from '../images/logo.inline.svg'
import Rock1 from '../images/rock1.inline.svg'
import Rock2 from '../images/rock2.inline.svg'
import Rock3 from '../images/rock3.inline.svg'
import Rock4 from '../images/rock4.inline.svg'
import Rock5 from '../images/rock5.inline.svg'
import gsap from 'gsap';
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.png'

export default function Homepage() {


  useEffect(()=>{
    const tl = gsap.timeline()
    const rocks = gsap.utils.toArray(['.rock'])
    const wrapper = gsap.utils.toArray(['.mainWrapper'])
    const text = gsap.utils.toArray(['.text'])
    const logo = gsap.utils.toArray(['.logo'])
    const links = gsap.utils.toArray(['ul li, a'])

    const removeInlineStyles = (elements) => {
      elements.map(element => element.setAttribute('style', ''))
    }

    wrapper[0].classList.remove('invisible')

    tl.fromTo(rocks, {opacity:0, scale:1.5}, {opacity:1, scale:1, stagger:0.2, onComplete:() => removeInlineStyles(rocks)})
      .fromTo(logo, {opacity:0, scale:1.5}, {opacity:1, scale:1, onComplete:() => removeInlineStyles(logo)}, "-=95%")
      .fromTo(text, {opacity:0, y:30}, {opacity:1, y:0, stagger:0.1, onComplete:() => removeInlineStyles(text)}, "-=50%")
      .fromTo(links, {opacity:0, y:30}, {opacity:1, y:0, stagger:0.1, 
        onComplete:() => {
          removeInlineStyles(links)
          addTransitions()
        }
      }, "-=25%")

      const addTransitions = () => {
        links.map(link => link.classList.add('duration-700'))
      }
  })

  return(
    <div className="mainWrapper invisible h-screen w-screen bg-prRed text-prYellow flex flex-row justify-center flex-wrap overflow-y-scroll overflow-x-hidden">
      <Helmet>
        <link name="icon" type="image/png" href={favicon} />
        <title>People Rock | Contemporary employment support for extraordinary people.</title>
      </Helmet>
      <div className="w-screen flex z-10">
        <Logo className="logo mt-12 mb-12 w-100 self-start grow" />
      </div>
      <div className="text-center px-10 align-center relative sm:px-10 drop-shadow-sm md:max-w-3xl md:px-0  lg:max-w-3xl xl:max-w-6xl z-10 ">
        <h1 className='text text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-7xl mb-12 '>Contemporary employment support for extraordinary people.</h1>
        <p className='text md:text-1xl lg:text-1xl xl:text-2xl mb-4'>We’re working on our full website and it should be up as soon as you can say "jackieweaverforprimeminister"... only joking it should be sooner than that.</p>
        <p className='text md:text-1xl lg:text-1xl xl:text-2xl mb-12'>In the meantime why not give us a follow or get in touch below.</p>
        <ul className='text-2xl md:text-2xl lg:text-2xl xl:text-3xl mx-auto'>
          <li className='inline-block mr-8 hover:scale-150'><a target="_blank" href="https://www.linkedin.com/company/people-rock-uk"><FaLinkedin /></a></li>
          <li className='inline-block mr-8 hover:scale-150'><a target="_blank" href="https://facebook.com"><FaFacebook /></a></li>
          <li className='inline-block mr-8 hover:scale-150'><a target="_blank" href="https://twitter.com/peoplerockuk"><FaTwitter /></a></li>
          <li className='inline-block hover:scale-150'><a target="_blank" href="https://instagram.com/peoplerock.uk"><FaInstagram /></a></li>
        </ul>
        <a href="mailto:sayhi@peoplerock.uk" className='inline-block mt-12 mb-12 border py-4 px-8 border-prYellow rounded-full hover:bg-prYellow hover:text-prRed'>Email us</a>
      </div>
      <div className="z-0 absolute min-h-screen w-screen bg-prRed text-prYellow flex flex-row justify-center items-center">
        <Rock3 className="rock [width:50vw] h-auto" />
        <Rock1 className="rock [width:20vw] [max-width:300px] h-auto absolute top-0 left-0" />
        <Rock2 className="rock [width:20vw] [max-width:300px] h-auto absolute bottom-0 right-0 w-max" />
        <Rock4 className="rock [width:30vw] [max-width:450px] h-auto absolute top-0 right-0" />
        <Rock5 className="rock [width:30vw] [max-width:450px] h-auto absolute bottom-0 left-0" />
      </div>
    </div>
  ) 
}
